export default function Lang(onlyCurrentLang = false) {
    let currentLanguage = localStorage.getItem('currentLanguage');
    if (!currentLanguage) {
        currentLanguage = 'ru';
    }

    if (onlyCurrentLang === true) {
        return currentLanguage;
    }

    let languages = {};

    let ru = {
        'header-nav-persons': 'Список персон',
        'header-nav-families': 'Семьи',
        'header-nav-trees': 'Деревья',
        'header-nav-albums': 'Альбомы',
        'header-nav-statistics': 'Статистика',
        'header-nav-contacts': 'Контакты',

        'modal-contact-info': 'Контактная информация',

        'index-random-persons': 'Случайные персоны',
        'index-view-all-persons': 'Показать все персоны',
        'index-families': 'Семьи',
        'index-trees': 'Генеалогические деревья',
        'index-albums': 'Фотоальбомы',
        'index-stat': 'Статистика',

        'breadcrumb-item-index': 'Главная',
        'breadcrumb-item-persons': 'Список персон',
        'breadcrumb-item-family': 'Семьи',

        'persons-h2': 'Список персон',

        'person-education_and_military_service': 'Образование и военная служба',
        'person-awards': 'Достижения и награды',
        'person-family_ties': 'Родственные связи',
        'person-album': 'Фотоальбом',
        'person-bio': 'Биография',

        'placeholder-search': 'Поиск персоны',
        'empty-result': 'Такой персоны нет в базе',

        'sort-set-0': 'Алфавитная сортировка »',
        'sort-set-1': 'Стандартная сортировка »',

        'person-mfiles': 'Медиа-файлы',
        'person-events-life': 'События жизни',

        'Общее количество персон в базе данных': 'Общее количество персон в базе данных',
        'Из них в живых': 'Из них в живых',
        'Мужчин': 'Мужчин',
        'Женщин': 'Женщин',
        '|родитель - ребенок|': 'Количество связей  "родитель - ребенок"',
        '|муж - жена|': 'Количество связей  "муж - жена"',

        'Род.:': 'Род.:',
        'Ум.:': 'Ум.:',
        '<пр>': '<пр>',
        '<биол>': '<биол>',
        '<е.к.>': '<е.к.>',

        'Бракосочетание': 'Бракосочетание',
        'Служба в армии': 'Служба в армии',
        'Участники события': 'Участники события',

        'год': 'год',
        'года': 'года',
        'лет': 'лет',
        'г.': 'г.',

        'Января': 'Января',
        'Февраля': 'Февраля',
        'Марта': 'Марта',
        'Апреля': 'Апреля',
        'Мая': 'Мая',
        'Июня': 'Июня',
        'Июля': 'Июля',
        'Августа': 'Августа',
        'Сентября': 'Сентября',
        'Октября': 'Октября',
        'Ноября': 'Ноября',
        'Декабря': 'Декабря',

        'Свояки (мужья своячениц)': 'Свояки (мужья своячениц)',
        'Правнучатые племянники / племянницы': 'Правнучатые племянники / племянницы',

        'от 1-й жены': 'от 1-й жены',
        'от 2-й жены': 'от 2-й жены',
        'от 3-й жены': 'от 3-й жены',
        'от 4-й жены': 'от 4-й жены',
        'от 4-й жены': 'от 5-й жены'
    };

    let en = {
        'header-nav-persons': 'List of persons',
        'header-nav-families': 'Families',
        'header-nav-trees': 'Trees',
        'header-nav-albums': 'Albums',
        'header-nav-statistics': 'Statistics',
        'header-nav-contacts': 'Contacts',

        'modal-contact-info': 'Contact Information',

        'index-random-persons': 'Random people',
        'index-view-all-persons': 'Show all persons',
        'index-families': 'Families',
        'index-trees': 'Family trees',
        'index-albums': 'Photo albums',
        'index-stat': 'Statistics',

        'breadcrumb-item-index': 'Home',
        'breadcrumb-item-persons': 'List of persons',
        'breadcrumb-item-family': 'Families',

        'persons-h2': 'List of persons',

        'person-education_and_military_service': 'Education and military service',
        'person-awards': 'Achievements and awards',
        'person-family_ties': 'Family ties',
        'person-album': 'Photo album',
        'person-bio': 'Biography',

        'placeholder-search': 'Search for a person',
        'empty-result': 'This person is not in the database',

        'sort-set-0': 'Alphabetical sorting »',
        'sort-set-1': 'Standard sorting »',

        'person-mfiles': 'Media files',
        'person-events-life': 'Life events',

        'Общее количество персон в базе данных': 'Total number of persons in the database',
        'Из них в живых': 'Of them are alive',
        'Мужчин': 'Men',
        'Женщин': 'Women',
        '|родитель - ребенок|': 'Number of parent-child connections',
        '|муж - жена|': 'Number of husband-wife relationships',

        'Родители': 'Parents',
        'Дети': 'Children',
        'Внуки': 'Grandchildren',
        'Правнуки': 'Great-grandchildren',
        'Праправнуки': 'Great-great-grandchildren',
        'Жена': 'Wife',
        'Муж': 'Husband',
        'Свёкор': 'Father-in-law',
        'Свекровь': 'Mother in law',
        'Девери': 'Devery',
        'Золовки': 'Sisters-in-law',
        'Тесть': 'Father-in-law',
        'Тёща': 'Mother-in-law',
        'Сваты / сватьи': 'Matchmakers / matchmakers',
        'Шурины': 'Brothers-in-law',
        'Свояченицы': 'Sisters-in-law',
        'Зятья': 'Sons-in-law',
        'Невестки (снохи)': 'Daughters-in-law',
        'Зятья (мужья сестёр)': 'Brothers-in-law (sisters\' husbands)',
        'Невестки (жёны братьев)': 'Daughters-in-law (brothers\' wives)',
        'Ятровки (жёны деверей)': 'Yatrovki (wives of brothers-in-law)',
        'Шуричи': 'Shurichi',
        'Свояки': 'Brothers-in-law',
        'Отец': 'Father',
        'Мать': 'Mother',
        'Братья / сёстры': 'Brothers/sisters',
        'Дедушки / бабушки': 'Grandparents',
        'Прадедушки / прабабушки': 'Great-grandparents  ',
        'Прапрадедушки / Прапрабабушки': 'Great-great-grandparents/great-great-grandparents',
        'Дяди / тёти': 'Uncles/aunts',
        'Двоюродные братья / сёстры': 'Cousins',
        'Племянники / племянницы': 'Nephews/nieces ',
        'Внучатые племянники': 'Great-nephews',
        'Внучатые племянники / племянницы': 'Great-nephews',
        'Внучатые двоюродные племянники': 'Great-great-nephews',
        'Внучатые двоюродные племянники(цы)': 'Great-great-nephews',
        'Правнучатые племянники': 'Great-great-nephews',
        'Двоюродные дяди / тёти': 'Cousins/aunts',
        'Двоюродные племянники / племянницы': 'Great cousins/nephews',
        'Троюродные братья / сёстры': 'Second cousins',
        'Двоюродные деды / бабушки': 'Great-uncles/grandmothers',
        'Двоюродные прадеды / прабабушки': 'Great-great-grandparents',
        'Троюродные деды / бабушки': 'Second cousins',
        'Троюродные дяди / тёти': 'Second cousins/aunts',
        'Троюродные племянники(цы)': 'Second cousins',

        'Род.:': 'Rd.:',
        'Ум.:': 'Rp.:',
        '<пр>': '<pr>',
        '<биол>': '<biol>',
        '<е.к.>': '<half-breed>',

        'Бракосочетание': 'Marriage',
        'Служба в армии': 'Military service',
        'Участники события': 'Event participants',

        'год': 'yr.',
        'года': 'yr.',
        'лет': 'yr.',
        'г.': 'yr.',

        'Января': 'January',
        'Февраля': 'February',
        'Марта': 'March',
        'Апреля': 'April',
        'Мая': 'May',
        'Июня': 'June',
        'Июля': 'July',
        'Августа': 'August',
        'Сентября': 'September',
        'Октября': 'October',
        'Ноября': 'November',
        'Декабря': 'December',

        'Свояки (мужья своячениц)': 'Brothers-in-law (husbands of sisters-in-law)',
        'Правнучатые племянники / племянницы': 'Great-great-nephews/nieces',

        'Бывшие жёны': 'Ex-wives',
        'от 1-й жены': 'from 1st wife',
        'от 2-й жены': 'from 2st wife',
        'от 3-й жены': 'from 3st wife',
        'от 4-й жены': 'from 4st wife',
        'от 5-й жены': 'from 5st wife',

        'Троюродные племянники / племянницы': 'Second cousins ​​nephews/nieces',
        'Четвероюродные братья / сёстры': 'Fourth cousins'
    };

    let gr = {
        'header-nav-persons': 'Προσωπα',
        'header-nav-families': 'Οικογενειες',
        'header-nav-trees': 'Δεντρα',
        'header-nav-albums': 'Αλμπουμ',
        'header-nav-statistics': 'Στατιστικη',
        'header-nav-contacts': 'Επικοινωνια',

        'modal-contact-info': 'Στοιχεία επικοινωνίας',

        'index-random-persons': 'Τυχαια Προσωπα',
        'index-view-all-persons': 'Εμφανιση ολων των προσωπων',
        'index-families': 'Οικογενειες',
        'index-trees': 'Οικογενειακα δεντρα',
        'index-albums': 'Αλμπουμ φωτογραφιων',
        'index-stat': 'Στατιστικη',

        'breadcrumb-item-index': 'Αρχική',
        'breadcrumb-item-persons': 'Κατάλογος προσώπων',
        'breadcrumb-item-family': 'Οικογένειες',

        'persons-h2': 'Καταλογος προσωπων',

        'person-education_and_military_service': 'Εκπαίδευση και στρατιωτική θητεία',
        'person-awards': 'Επιτεύγματα και βραβεία',
        'person-family_ties': 'Οικογενειακοί δεσμοί',
        'person-album': 'Αλμπουμ φωτογραφιών',
        'person-bio': 'Βιογραφία',

        'placeholder-search': 'Αναζήτηση',
        'empty-result': 'Αυτό το άτομο δεν βρίσκεται στη βάση δεδομένων',

        'sort-set-0': 'Αλφαβητική ταξινόμηση »',
        'sort-set-1': 'Τυπική ταξινόμηση »',

        'person-mfiles': 'Αρχεία πολυμέσων',
        'person-events-life': 'Γεγονότα ζωής',

        'Общее количество персон в базе данных': 'Συνολικός αριθμός ατόμων στη βάση δεδομένων',
        'Из них в живых': 'Εν ζωή',
        'Мужчин': 'Ανδρες',
        'Женщин': 'Γυναίκες',
        '|родитель - ребенок|': 'Αριθμός συνδέσεων "γονέα-παιδιού"',
        '|муж - жена|': 'Αριθμός συνδέσεων "ο σύζυγος - η σύζυγος"',

        'Родители': 'Γονείς',
        'Дети': 'Τέκνα / Παιδιά',
        'Внуки': 'Εγγόνια',
        'Правнуки': 'Δισέγγονα',
        'Праправнуки': 'Τρισέγγονα',
        'Жена': 'η Σύζυγος',
        'Муж': 'ο Σύζυγος',
        'Свёкор': 'Πεθερός',
        'Свекровь': 'Πεθερά',
        'Девери': 'Κουνιάδος / Ανδραδελφός',
        'Золовки': 'Κουνιάδα / Aνδραδέλφη',
        'Тесть': 'Πεθερός',
        'Тёща': 'Πεθερά',
        'Сваты / сватьи': 'Συμπεθέρια',
        'Шурины': 'Κουνιάδος / Γυναικαδελφός',
        'Свояченицы': 'Κουνιάδα / Γυναικαδελφή',
        'Зятья': 'Γαμπροί',
        'Невестки (снохи)': 'Νύφη',
        'Зятья (мужья сестёр)': 'Γαμπροί',
        'Невестки (жёны братьев)': 'Νύφες',
        'Ятровки (жёны деверей)': 'Συννυφάδες',
        'Шуричи': 'Παιδιά κουνιάδων',
        'Свояки': 'Μπατζανάκηδες',
        'Отец': 'Πατέρας',
        'Мать': 'Μητέρα',
        'Братья / сёстры': 'Αδέλφια',
        'Дедушки / бабушки': 'Παππούδες',
        'Прадедушки / прабабушки': 'Προπάππουδες  ',
        'Прапрадедушки / Прапрабабушки': 'Προ-προπάππουδες',
        'Дяди / тёти': 'Θείος / θεία',
        'Двоюродные братья / сёстры': 'Πρώτα ξαδέλφια',
        'Племянники / племянницы': 'Ανιψιός / ανιψιά ',
        'Внучатые племянники': 'Εγγόνια αδελφών',
        'Внучатые племянники / племянницы': 'Εγγόνια αδελφών',
        'Внучатые двоюродные племянники': 'Εγγόνια πρώτου ξάδελφου',
        'Внучатые двоюродные племянники(цы)': 'Εγγόνια πρώτου ξάδελφου',
        'Правнучатые племянники': 'Δισέγγονα αδελφού/ής',
        'Двоюродные дяди / тёти': 'Πρώτα ξαδέλφια των γονέων',
        'Двоюродные племянники / племянницы': 'Μικροανιψιός/ά Παιδιά των ξαδέλφων',
        'Троюродные братья / сёстры': 'Δεύτερα ξαδέλφια',
        'Двоюродные деды / бабушки': 'Αδέλφια των παππούδων',
        'Двоюродные прадеды / прабабушки': 'Αδέλφια των προπάππουδων',
        'Троюродные деды / бабушки': 'Ξαδέλφια των παππούδων',
        'Троюродные дяди / тёти': 'Δεύτερα ξαδέλφια των γονέων',
        'Троюродные племянники(цы)': 'Ανίψια δεύτερων ξάδελφων',

        'Род.:': 'Γεν.:',
        'Ум.:': 'Απεβ.',
        '<пр>': '<θετοί.γ.>',
        '<биол>': '<βιο.γ.>',
        '<е.к.>': '<ημίαιμος>',

        'Бракосочетание': 'Γάμος',
        'Служба в армии': 'Στρατιωτική θητεία',
        'Участники события': 'Συμμετέχοντες',

        'год': 'χρ.',
        'года': 'χρ.',
        'лет': 'χρ.',
        'г.': '.',

        'Января': 'Ιανουάριος',
        'Февраля': 'Φεβρουάριος',
        'Марта': 'Μάρτιος',
        'Апреля': 'Απρίλιος',
        'Мая': 'Μάιος',
        'Июня': 'Ιούνιος',
        'Июля': 'Ιούλιος',
        'Августа': 'Αύγουστος',
        'Сентября': 'Σεπτέμβριος',
        'Октября': 'Οκτώβριος',
        'Ноября': 'Νοέμβριος',
        'Декабря': 'Δεκέμβριος',

        'Свояки (мужья своячениц)': 'Κουνιάδοι (σύζυγοι κουνιάδων)',
        'Правнучатые племянники / племянницы': 'Προ-προ-ανήψι/ανίψια',

        'Бывшие жёны': 'Πρώην συζυγίες',
        'от 1-й жены': 'από την 1η σύζυγο',
        'от 2-й жены': 'από την 2η σύζυγο',
        'от 3-й жены': 'από την 3η σύζυγο',
        'от 4-й жены': 'από την 4η σύζυγο',
        'от 5-й жены': 'από την 5η σύζυγο',

        'Троюродные племянники / племянницы': 'Ανίψια δεύτερων ξάδελφων',
        'Четвероюродные братья / сёстры': 'Τρίτα ξαδέρφια'
    };

    if (currentLanguage == 'ru') {
        languages = ru;
    }

    if (currentLanguage == 'en') {
        languages = en;
    }

    if (currentLanguage == 'gr') {
        languages = gr;
    }

    return languages;
}