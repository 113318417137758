<template>
	<header class="container-fluid p-3 fixed-top --shadow-sm">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-10 col-lg-9 col-xl-2 col-md-8 col-sm-7 logo_image">
					<router-link tag="a" :to="{ name: 'index' }">
						<img class="d-none d-sm-block main_logo" src="@/assets/images/web-logo.png">
						<img class="d-block d-sm-none main_logo_min" src="@/assets/images/web-logo2.png">
					</router-link>
				</div>

				<div class="d-sm-none d-block col-2">
					<button @click.prevent="collapsedMobMenuClick()" type="button"
						class="btn btn-sm btn-outline-primary w-100 collapsed" data-bs-toggle="collapse"
						data-bs-target="#mobile_menu" aria-expanded="false" aria-controls="mobile_menu">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
							class="bi bi-list" viewBox="0 0 16 16">
							<path fill-rule="evenodd"
								d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z">
							</path>
						</svg>
					</button>
				</div>

				<div class="collapse" id="mobile_menu" :class="{ 'show': collapsedMobMenu }">
					<div class="row mt-2">
						<div class="col-12">
							<router-link v-for="(r, i) in navLinks" :key="i" tag="a" :exact="r.active"
								active-class="active" :to="{ name: r.route }" v-slot="{ isActive, isExactActive }"
								@click="collapsedMobMenuClick()">
								{{ lang[r.nameKey] }}
							</router-link>
							<a href="javascript:;" data-bs-toggle="modal" data-bs-target="#feedBack">
								{{ lang[contactLink.nameKey] }}
							</a>

							<div class="dropdown">
								<a class="dropdown-toggle" type="button" id="dropdownMenuButton1"
									data-bs-toggle="dropdown" aria-expanded="false">
									{{ currentLanguage }}
								</a>
								<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
									<li v-for="(l, i) in allLanguages" :key="i" @click.prevent="changeLanguage(l.code)">
										<a class="dropdown-item" href="javascript:;">{{ l.name }}</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div
					class="col-8 offset-2 menu_links d-flex justify-content-between d-none d-sm-none d-md-none d-lg-none d-xl-flex">
					<router-link v-for="(r, i) in navLinks" :key="i" tag="a" :exact="r.active" active-class="active"
						:to="{ name: r.route }" v-slot="{ isActive, isExactActive }">
						{{ lang[r.nameKey] }}
					</router-link>
					<a href="javascript:;" data-bs-toggle="modal" data-bs-target="#feedBack">
						{{ lang[contactLink.nameKey] }}
					</a>

					<div class="dropdown">
						<a class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
							aria-expanded="false">
							{{ currentLanguage }}
						</a>
						<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
							<li v-for="(l, i) in allLanguages" :key="i" @click.prevent="changeLanguage(l.code)">
								<a class="dropdown-item" href="javascript:;">{{ l.name }}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>


			<!-- mobile menu !-->
			<div class="row d-none d-xl-none d-sm-block">
				<div class="my-menu-lg col-12 mt-3 d-flex justify-content-between">
					<router-link v-for="(r, i) in navLinks" :key="i" tag="a" :exact="r.active" active-class="active"
						:to="{ name: r.route }" v-slot="{ isActive, isExactActive }">
						{{ lang[r.nameKey] }}
					</router-link>
					<a href="javascript:;" data-bs-toggle="modal" data-bs-target="#feedBack">
						{{ lang[contactLink.nameKey] }}
					</a>
				</div>
			</div>
		</div>
	</header>

	<router-view />

	<div class="container-fluid section-catalog p-1 footer_block">
		<div class="container">
			<div class="row">
				<div class="col-12 d-flex justify-content-between">
					<div>&copy; Megarogrevena</div>
					<div>{{ getCurrentDate() }}</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="feedBack" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">{{ lang['modal-contact-info'] }}</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12 mb-3" v-if="contactsText">{{ contactsText }}</div>
						<div class="col-12 d-flex align-items-start">
							<img class="contactPhoto me-3" :src="getContactPhoto">
							<div>
								<div class="FIOperson fw-bold"><a href="/person/1">Κακαφίκας Σέργιος του Λιούντβιγκ</a>
								</div>
								<div class="mt-2">Email: <a href="mailto:averoff.s@gmail.com">averoff.s@gmail.com</a>
								</div>
								<div class="mt-1">Phone: <a href="tel:+306972509053">+306972509053</a></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Lang from '@/functions/Languages.js'

export default {
	data: () => ({
		navLinks: [
			{
				route: 'persons',
				name: 'Список персон',
				nameKey: 'header-nav-persons',
				active: true,
				icon: '👨‍👩‍👧‍👦'
			},
			{
				route: 'families',
				name: 'Семьи',
				nameKey: 'header-nav-families',
				icon: '💞'
			},
			{
				route: 'trees',
				name: 'Деревья',
				nameKey: 'header-nav-trees',
				icon: '🌳'
			},
			{
				route: 'albums',
				name: 'Альбомы',
				nameKey: 'header-nav-albums',
				icon: '🖼️'
			},
			{
				route: 'statistics',
				name: 'Статистика',
				nameKey: 'header-nav-statistics',
				icon: '📈'
			}
		],

		contactLink: {
			name: 'Контакты',
			icon: '📠',
			nameKey: 'header-nav-contacts',
		},

		getContactPhoto: `https://bd.megarogrevena.gr/person1/foto.jpg`,

		lang: {},
		currentLanguage: 'ru',

		allLanguages: [
			{
				code: 'ru',
				name: 'Русский'
			},
			{
				code: 'en',
				name: 'English'
			},
			{
				code: 'gr',
				name: 'Ελληνικά'
			}
		],

		collapsedMobMenu: false,

		contactsText: localStorage.getItem(`contactsText`) || null
	}),

	methods: {
		getContactText() {

		},

		getCurrentDate() {
			let today = new Date();
			const dd = String(today.getDate()).padStart(2, '0');
			const mm = String(today.getMonth() + 1).padStart(2, '0');
			const yyyy = today.getFullYear();

			today = mm + '.' + dd + '.' + yyyy;

			return today;
		},

		changeLanguage(code) {
			localStorage.setItem('currentLanguage', code);
			location.reload();
		},

		collapsedMobMenuClick() {
			this.collapsedMobMenu = (this.collapsedMobMenu === true) ? false : true;
		}
	},

	mounted() {
		this.lang = Lang();
		this.currentLanguage = Lang(true);
	}
}
</script>