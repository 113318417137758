import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/style.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import VueLazyLoad from 'vue3-lazyload'

createApp(App).
    use(router).
    use(bootstrap).
    use(VueLazyLoad, {
        error: 'http://bd.megarogrevena.gr/no-image.jpg',
        loading: 'http://bd.megarogrevena.gr/no-image.jpg'
    }).
    mount('#app')
