import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'index',
		component: () => import('@/views/Index.vue')
	},
	{
		path: '/persons',
		name: 'persons',
		component: () => import('@/views/Persons.vue')
	},
	{
		path: '/families',
		name: 'families',
		component: () => import('@/views/Families.vue')
	},
	{
		path: '/statistics',
		name: 'statistics',
		component: () => import('@/views/Persons.vue')
	},
	{
		path: '/trees',
		name: 'trees',
		component: () => import('@/views/Persons.vue')
	},
	{
		path: '/albums',
		name: 'albums',
		component: () => import('@/views/Persons.vue')
	},
	{
		path: '/contacts',
		name: 'contacts',
		component: () => import('@/views/Persons.vue')
	},
	{
		path: '/family/:id/:name',
		name: 'family',
		component: () => import('@/views/Families.vue')
	},
	{
		path: '/person/:id',
		name: 'person',
		component: () => import('@/views/PersonPage.vue')
	},
	{
		path: '/album/:id',
		name: 'album',
		component: () => import('@/views/AlbumPage.vue')
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {
	if (to.name != "index" && to.name != "persons" && to.name != 'family' && to.name != "person" && to.name != "album") {

		if (from.name == 'index') {
			// document.getElementById(`anchor_${to.name}`).scrollIntoView({
			// 	behavior: 'smooth',
			// 	block: 'start'
			// });

			const headerHeight = 63;
			const element = document.getElementById(`anchor_${to.name}`);
			const elementPosition = element.getBoundingClientRect().top + window.scrollY;
			window.scrollTo({
				top: elementPosition - headerHeight,
				behavior: 'smooth'
			});
		} else {
			next('/');

			let intervalWait = setInterval(() => {
				const anchor = document.getElementById(`anchor_${to.name}`);
				if (anchor) {
					clearInterval(intervalWait);
					// document.getElementById(`anchor_${to.name}`).scrollIntoView({
					// 	behavior: 'smooth',
					// 	block: 'start'
					// });

					const headerHeight = 63;
					const element = document.getElementById(`anchor_${to.name}`);
					const elementPosition = element.getBoundingClientRect().top + window.scrollY;
					window.scrollTo({
						top: elementPosition - headerHeight,
						behavior: 'smooth'
					});
				}
			}, 500);
		}

		return;
	} else {
		next();

		setTimeout(() => {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}, 300);
	}
});

export default router
